import React from "react";
import { useOrg } from "../../context/OrgContext";

const Footer = () => {
  const { styles } = useOrg();

  return (
    <footer className="flex justify-center p-3" style={styles.footer}>
      <section>{`Powered by Evolve Credit`}</section>
    </footer>
  );
};
export default Footer;
