import React, { useEffect, useState } from "react";
import { Input, Form, Col, Row, Checkbox, Button, Divider, Modal } from "antd";
import { useQuery } from "react-query";
import { QuestionCircleFilled } from "@ant-design/icons";
import { useOrg } from "../../context/OrgContext";
import { Request } from "../../utils/Axios";

function AccountForm({ bank, setView }) {
  const { color } = useOrg();
  const formRef = React.createRef();
  const [openQuestion, setQuestion] = useState(false);

  const { data: banks } = useQuery("bank", () =>
    Request.get(`https://api.paystack.co/bank`)
  );

  const findName = (code) =>
    banks?.data?.data?.find((bank) => bank.code === code)?.name;

  const handleSubmit = () => {
    setView("1");
  };
  useEffect(() => {
    formRef?.current?.setFieldsValue({
      accountname: bank?.account_name,
      accountnumber: bank?.account_number,
      bankname: findName(bank?.bank_code),
    });

    // eslint-disable-next-line
  }, [bank, banks, formRef]);

  return (
    <div>
      <div>
        <h1 className="lg:text-xl text-lg font-bold text-center">
          You’re about to set up a debit mandate on your bank account
          <QuestionCircleFilled
            className="mx-2 -mb-2"
            onClick={() => setQuestion(true)}
          />
        </h1>
      </div>

      <Divider />
      <Form
        ref={formRef}
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
      >
        <Row gutter={[2, 2]}>
          <Col xs={24} md={24} lg={24}>
            <Form.Item label="Bank Name" name="bankname">
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <Form.Item label="Account Name" name="accountname">
              <Input size="large" readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} md={24} lg={24}>
            <Form.Item label="Account Number" name="accountnumber">
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col xs={24} md={24} lg={24}>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Please confirm that this is your account")
                        ),
                },
              ]}
            >
              <Checkbox>Yes, this is my account</Checkbox>
            </Form.Item>
          </Col>

          <Col xs={24} md={24} lg={24}>
            <Form.Item>
              <div className="w-full p-3 flex justify-center">
                <Button
                  type="primary"
                  style={{ background: color, width: "100%" }}
                  size="large"
                  htmlType="submit"
                >
                  Next
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal
        visible={openQuestion}
        footer={null}
        onCancel={() => setQuestion(false)}
      >
        <p className="text-lg my-2">
          A Direct Debit mandate gives service providers written permission to
          take payments from their customers bank accounts. Payments cannot be
          collected until the mandate has been signed and agreed by the
          customer.
        </p>

        <ol className="px-6">
          <li className="list-disc text-lg my-2">
            The Direct Debit is one of the safest ways of paying your bills.
          </li>

          <li className="text-lg my-2 list-disc">
            You will be given advance notice [at least 10 days] before payments
            are taken.
          </li>

          <li className="text-lg my-2 list-disc">
            All collections are covered under the Direct Debit Guarantee.
          </li>
        </ol>
      </Modal>
    </div>
  );
}

export default AccountForm;
