import React, { useState } from "react";
import useSWR, { trigger } from "swr";
import { Button, Divider, Form, Input, Row, Col, message } from "antd";
import { useLocation } from "react-router-dom";
import { useOrg } from "../../context/OrgContext";
import { Request } from "../../utils/Axios";
import SyncFiles from "../customSVG/SyncSuceess";

function RequestOTP(props) {
  const { color, setDownloadForm } = useOrg();
  const [loadingRequestOtp, setLoadingRequestOTP] = useState(false);

  const [validateMandate, setValidateMandate] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const { data: mandate, error } = useSWR(
    `/test/credit-order/${query.get("co-ref")?.trim()}`,
    (url) => Request.get(url)
  );

  const { data: mandateStatus, errorMandateStatus } = useSWR(
    `/test/credit-order/${query.get("co-ref")?.trim()}/mandate/get-status`,
    (url) => Request.get(url)
  );

  const handleRequestOTP = async () => {
    try {
      // This functions request for  OTP
      setLoadingRequestOTP(true);
      const requestOTP = await Request.get(
        `/test/credit-order/mandate/form/${query
          .get("co-ref")
          ?.trim()}/request-mandate-otp`
      );

      if (requestOTP?.data?.status === "error") {
        setLoadingRequestOTP(false);
        message.error(requestOTP?.data?.message);
        return;
      }
      trigger(`/test/credit-order/${query.get("co-ref")?.trim()}`);
      setLoadingRequestOTP(false);
    } catch (error) {
      setLoadingRequestOTP(false);
      message.error("something went wrong, please try again");
    }
  };

  const handleValidateOTP = async (values) => {
    setValidateMandate(true);
    let count = 1;
    let payload = {};
    for (const [key, value] of Object.entries(values)) {
      const currrentcount = count++;

      payload[`param${currrentcount}`] = key;
      payload[`value${currrentcount}`] = value;
    }

    try {
      const OTPValidation = await Request.put(
        `/test/credit-order/mandate/form/${query.get(
          "co-ref"
        )}/activate-mandate-otp`,
        {
          ...payload,
          reqs: Object.entries(values).length,
        }
      );

      if (OTPValidation?.data?.status === "error") {
        setValidateMandate(false);
        message.error(OTPValidation?.data?.message);
      }

      trigger(`/test/credit-order/${query.get("co-ref")}`);
      setLoadingRequestOTP(false);
    } catch (error) {
      setValidateMandate(false);
      trigger(`/test/credit-order/${query.get("co-ref")}`);
      message.error("Something went wrong, try again");
    }
  };

  if (false) {
    // dead end, ignore
    console.log(error, errorMandateStatus);
  }
  return (
    <div>
      {mandateStatus?.data?.activated ? (
        <div className="flex justify-center items-center flex-col">
          <SyncFiles />
          <p className="text-2xl my-3 text-center">
            Mandate successfully activated
          </p>
        </div>
      ) : (
        <>
          <h1 className="lg:text-xl text-lg font-bold text-center">
            You’re about to activate a debit mandate. In order to do this, you
            will need to provide a one-time password.
          </h1>

          <Divider />
          {!mandate?.data?.data?.mandate_otp_req_res ? (
            <Button
              onClick={handleRequestOTP}
              loading={loadingRequestOtp}
              type="primary"
              style={{ background: color, width: "100%" }}
              size="large"
            >
              Request OTP
            </Button>
          ) : (
            <div className="flex flex-col items-center">
              <p className="text-center text-xl my-2">Fill out the following</p>
              <Form layout="vertical" onFinish={handleValidateOTP}>
                <Row gutter={[29, 5]}>
                  {mandate?.data?.data?.mandate_otp_req_res?.authParams[0]
                    ?.param1 && (
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item
                        label={
                          mandate?.data?.data?.mandate_otp_req_res
                            ?.authParams[0]?.description1
                        }
                        name={
                          mandate?.data?.data?.mandate_otp_req_res
                            ?.authParams[0]?.param1
                        }
                        rules={[
                          {
                            required: true,
                            message: "required",
                          },
                        ]}
                      >
                        <Input
                          bordered={false}
                          placeholder="00000"
                          type="number"
                          style={{
                            borderBottom: "1px solid grey",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {mandate?.data?.data?.mandate_otp_req_res?.authParams[0]
                    ?.param2 && (
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item
                        label={
                          mandate?.data?.data?.mandate_otp_req_res
                            ?.authParams[0]?.description2
                        }
                        name={
                          mandate?.data?.data?.mandate_otp_req_res
                            ?.authParams[0]?.param2
                        }
                        rules={[
                          {
                            required: true,
                            message: "required",
                          },
                        ]}
                      >
                        <Input
                          bordered={false}
                          placeholder="00000"
                          type="number"
                          style={{
                            borderBottom: "1px solid grey",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {mandate?.data?.data?.mandate_otp_req_res?.authParams[0]
                    ?.param3 && (
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item
                        label={
                          mandate?.data?.data?.mandate_otp_req_res
                            ?.authParams[0]?.description3
                        }
                        name={
                          mandate?.data?.data?.mandate_otp_req_res
                            ?.authParams[0]?.param3
                        }
                        rules={[
                          {
                            required: true,
                            message: "required",
                          },
                        ]}
                      >
                        <Input
                          bordered={false}
                          placeholder="00000"
                          type="number"
                          style={{
                            borderBottom: "1px solid grey",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {mandate?.data?.data?.mandate_otp_req_res?.authParams[0]
                    ?.param4 && (
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item
                        label={
                          mandate?.data?.data?.mandate_otp_req_res
                            ?.authParams[0]?.description4
                        }
                        name={
                          mandate?.data?.data?.mandate_otp_req_res
                            ?.authParams[0]?.param4
                        }
                        rules={[
                          {
                            required: true,
                            message: "required",
                          },
                        ]}
                      >
                        <Input
                          bordered={false}
                          placeholder="00000"
                          type="number"
                          style={{
                            borderBottom: "1px solid grey",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Form.Item>
                  <div className="flex md:flex-row flex-col md:items-center  mt-5">
                    <Button
                      loading={validateMandate}
                      size="large"
                      htmlType="submit"
                      className="w-full rounded"
                      style={{
                        backgroundColor: color,
                      }}
                      type="primary"
                    >
                      Activate mandate
                    </Button>
                  </div>
                </Form.Item>
              </Form>
              {mandate?.data?.data?.bank_details?.bank_code &&
                mandate?.data?.data?.bank_details?.bank_code !== "070" && (
                  <div className="flex justify-center">
                    <Button
                      type="link"
                      style={{ color }}
                      onClick={handleRequestOTP}
                      loading={loadingRequestOtp}
                    >
                      Resend OTP
                    </Button>
                  </div>
                )}
            </div>
          )}
        </>
      )}
      <div className="flex justify-center mt-4">
        <Button
          type="link"
          style={{ color: "green" }}
          onClick={() => setDownloadForm(true)}
        >
          Would you like to activate mandate at your bank? click here.
        </Button>
      </div>
    </div>
  );
}

export default RequestOTP;
