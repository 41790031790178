import React, { useEffect, useState } from "react";

import { Input, Form, Col, Row, Button, Divider, message } from "antd";
import { useLocation } from "react-router-dom";

import { currencyFormatter } from "../../utils/currencyFormatter";
import { useOrg } from "../../context/OrgContext";
import { Request } from "../../utils/Axios";
import {
  formatLoanTurn,
  formatLoanChargeFreq,
} from "../../utils/TimeFormatter";
function LoanOfferForm({ order }) {
  const formRef = React.createRef();

  const query = new URLSearchParams(useLocation().search);
  const { color } = useOrg();
  const [loading, setLoading] = useState(false);

  const handleInitMandate = async () => {
    try {
      // This function init mandate
      setLoading(true);
      const initMandate = await Request.get(
        `/test/credit-order/mandate/form/${query
          .get("co-ref")
          ?.trim()}/initiate-mandate`
      );

      if (initMandate?.data?.status === "error") {
        setLoading(false);
        message.error(initMandate?.data?.message);
        return;
      }

      setLoading(false);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      message.error("something went wrong, please try again");
    }
  };

  useEffect(() => {
    formRef?.current?.setFieldsValue({
      amount: currencyFormatter(order?.terms?.total_debit),
      instalmentamount: currencyFormatter(order?.terms?.onetime_debit),
      tenure: `${order?.terms?.tenure} Month(s)`?.toLocaleUpperCase(),
      frequency: order?.terms?.recurence?.toLocaleUpperCase(),
    });
    // eslint-disable-next-line
  }, [order]);

  return (
    <div>
      <div>
        <h1 className="lg:text-xl text-lg font-bold text-center">
          Repayment Summary
        </h1>
      </div>

      <Divider />

      <Form
        layout="vertical"
        initialValues={{ remember: true }}
        ref={formRef}
        onFinish={handleInitMandate}
      >
        <Row gutter={[2, 2]}>
          <Col xs={24} md={24} lg={24}>
            <Form.Item label="Total Amount Payable" name="amount">
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <Form.Item label="Instalment Amount" name="instalmentamount">
              <Input size="large" readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} md={24} lg={24}>
            <Form.Item name="tenure" label="Tenure">
              <Input size="large" readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} md={24} lg={24}>
            <Form.Item name="frequency" label="Charge frequency">
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <p className="text-center text-lg">{`Your bank account will be charged ${currencyFormatter(
            order?.terms?.onetime_debit
          )}, ${formatLoanTurn(
            order?.terms?.tenure
          )} for ${formatLoanChargeFreq(order?.terms?.recurence)}`}</p>
          <Col xs={24} md={24} lg={24}>
            <Form.Item>
              <div className="w-full p-3 flex justify-center">
                <Button
                  type="primary"
                  style={{ background: color, width: "100%" }}
                  size="large"
                  loading={loading}
                  htmlType="submit"
                >
                  Set Up Debit Mandate
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default LoanOfferForm;
