import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Spin } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import LoanOfferForm from "../components/DebitmandateFlow/LoanOfferForm";
import AccountForm from "../components/DebitmandateFlow/AccountForm";
import { Request } from "../utils/Axios";
import { useOrg } from "../context/OrgContext";
import DebitButton from "../components/DebitmandateFlow/DebitButton";

const Home = () => {
  const [currentView, setView] = useState("0");
  const query = new URLSearchParams(useLocation().search);
  const { setData, logo } = useOrg();
  const history = useHistory();
  // Queries
  const { data, isFetching } = useQuery("verify", () =>
    Request.post("/test/credit-order/mandate/form/verify", {
      "ou-ref": query.get("ou-ref")?.trim(),
      "co-ref": query.get("co-ref")?.trim(),
    })
  );

  const { data: isMandateData } = useQuery("isMandate", () =>
    Request.get(`/test/credit-order/${query.get("co-ref")?.trim()}`)
  );

  const { data: dataCreditOrder } = useQuery("credit order", () =>
    Request.get(`/test/credit-order/${query.get("co-ref")?.trim()}`)
  );

  const { data: dataOrg } = useQuery("org", () =>
    Request.post("/test/credit-order/mandate/form/org", {
      "ou-ref": query.get("ou-ref")?.trim(),
      "co-ref": query.get("co-ref")?.trim(),
    })
  );

  useEffect(() => {
    if (data?.data?.status !== "success" && isFetching === false) {
      history.push("/");
      return;
    }

    setData({ ...dataOrg?.data?.data, ...dataOrg?.data?.data1 });
    // eslint-disable-next-line
  }, [dataOrg, isFetching]);
  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      tip="Loading..."
      spinning={isFetching && data?.data?.message !== "Mandate URL is Valid"}
    >
      <section className="py-6 p-5">
        {logo && (
          <div className="flex items-center my-10 flex-col">
            <img alt="evolve logo" src={logo} width="80" height="80" />
          </div>
        )}
        <div className="lg:w-1/3 m-auto border rounded-md p-4">
          {isMandateData?.data?.data?.mandate_id ? (
            <DebitButton />
          ) : (
            <>
              {currentView === "0" && (
                <AccountForm
                  bank={dataCreditOrder?.data?.data?.bank_details}
                  setView={setView}
                />
              )}

              {currentView === "1" && (
                <LoanOfferForm
                  order={dataCreditOrder?.data?.data}
                  setView={setView}
                />
              )}
            </>
          )}
        </div>
      </section>
    </Spin>
  );
};

export default Home;
