import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./components/layout/Footer";
import "./styles/App.less";
import Home from "./pages/HomePage";
import Landing from "./pages/Landing";

function App() {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div className="flex-grow h-full">
        <Router>
          <Switch>
            <Route path="/" exact={true}>
              <Landing />
            </Route>

            <Route path="/mandate" exact={true}>
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>

      <div className="flex-shrink-0">
        <Footer />
      </div>
    </div>
  );
}

export default App;
